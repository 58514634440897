var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reloan-accounting" }, [
    _c("img", {
      staticClass: "main-img",
      attrs: { src: _vm.status && _vm.pageConfig[_vm.status][_vm.brand] },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "title", class: [_vm.brand + "-title"] }, [
      _vm._v(
        "\n        " +
          _vm._s(
            _vm.status && _vm.$t("webview.reloanAccounting.title." + _vm.status)
          ) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "action" },
      [
        _vm.status === "progress"
          ? _c("loanProgress", {
              attrs: { value: _vm.value },
              on: {
                complete: function ($event) {
                  _vm.laterStatus = "complete"
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.status === "complete"
          ? _c(
              "button",
              {
                class: [_vm.brand + "-btn"],
                style: { background: _vm.primaryColor },
                on: { click: _vm.closePage },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("webview.reloanAccounting.button")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }