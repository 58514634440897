<!-- @Author: chengyuzhang -->
<!-- @Date: 2020-09-25 10:58:52.631 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-11-16 18:56:09 -->

<template>
    <div class="reloan-accounting">
        <img
            class="main-img"
            :src="status && pageConfig[status][brand]"
        >

        <div
            class="title"
            :class="[`${brand}-title`]"
        >
            {{ status && $t(`webview.reloanAccounting.title.${status}`) }}
        </div>

        <div class="action">
            <loanProgress
                v-if="status === 'progress'"
                :value="value"
                @complete="laterStatus = 'complete'"
            />

            <button
                v-if="status === 'complete'"
                :class="[`${brand}-btn`]"
                :style="{background: primaryColor}"
                @click="closePage"
            >
                {{ $t('webview.reloanAccounting.button') }}
            </button>
        </div>
    </div>
</template>

<script type="text/babel">
import {mapState} from 'vuex';

import Interface from 'ssr-common/util/webview/yqg-webview-interface';

import prefetch from 'easycash/common/mixin/server-prefetch';
import Loan from 'easycash/common/resource/loan';

import cashkeyCompleteImg from './img/cashkey-complete.png';
import cashkeyProcessingImg from './img/cashkey-processing.gif';
import credkeyCompleteImg from './img/credkey-complete.png';
import credkeyProcessingImg from './img/credkey-processing.gif';
import easycashCompleteImg from './img/easycash-complete.png';
import easycashProcessingImg from './img/easycash-processing.gif';
import loanProgress from './progress';

const pageConfig = {
    complete: {
        easycash: easycashCompleteImg,
        cashkey: cashkeyCompleteImg,
        credkey: credkeyCompleteImg
    },
    progress: {
        easycash: easycashProcessingImg,
        cashkey: cashkeyProcessingImg,
        credkey: credkeyProcessingImg
    }
};

export default {
    name: 'ReloanAccounting',

    components: {
        loanProgress
    },

    mixins: [prefetch('reloanAccounting')],

    data() {
        return {
            laterValue: null,
            color: '#00c989',
            pageConfig,
            laterStatus: '',
            timmer: null,
            fetched: false
        };
    },

    computed: {
        ...mapState('baseData', [
            'primaryColor',
            'brand'
        ]),

        status() {
            const {reloanAccounting: {initStatus}, laterStatus} = this;

            return laterStatus || initStatus;
        },

        value() {
            const {reloanAccounting: {initValue}, laterValue} = this;

            return (laterValue === null ? initValue : laterValue) * 100;
        }
    },

    mounted() {
        this.startQuery();
    },

    beforeDestroy() {
        clearTimeout(this.timer);
    },

    methods: {
        async initFetch({header}) {
            const {isCalcCreditsInReview, processingRate} = await this.fetchData(header);
            const reloanAccounting = {};
            if (isCalcCreditsInReview === false) {
                reloanAccounting.initStatus = 'complete';
            }

            if (isCalcCreditsInReview === true) {
                reloanAccounting.initStatus = 'progress';
                reloanAccounting.initValue = processingRate || 0;
            }

            return reloanAccounting;
        },

        async fetchData(header) {
            try {
                const {data: {body}} = await Loan.queryCalcCreditsStatus({headers: header || {}, hideToast: true});

                return body || {};
            } catch ({data: {status: {detail} = {}} = {}}) {
                this.$toast(detail);

                return {};
            }
        },

        async startQuery() {
            const {processingRate, isCalcCreditsInReview} = await this.fetchData();
            if (isCalcCreditsInReview === false) {
                this.laterValue = 1;

                return;
            }

            this.laterValue = processingRate || null;
            if (isCalcCreditsInReview === true) {
                this.timer = setTimeout(() => {
                    this.startQuery();
                }, 5000);
            }
        },

        closePage() {
            Interface.closeWebPage();
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.reloan-accounting {
    height: 100vh;
    padding: 13.3vw 6.7vw;

    .main-img {
        margin: 0 auto 20px;
        width: 16.5vw;
    }

    .title {
        font-family: PingFangSC-Semibold, PingFang SC;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        color: #333;
    }

    .credmex-title {
        font-family: Lexend;
    }

    .action {
        margin: 16vw auto 0;
        text-align: center;

        button {
            max-width: 86.67vw;
            border: 0;
            outline: 0;
            width: 325px;
            height: 60px;
            border-radius: 8px;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
        }

        .credmex-btn {
            height: 0.48rem;
            border-radius: 0.12rem;
            font-family: Lexend;
            font-size: 0.16rem;
            font-style: normal;
            font-weight: 700;
            line-height: 165%;
        }
    }
}
</style>
