var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "progress" }, [
      _c("div", {
        staticClass: "inner",
        style: {
          background: _vm.primaryColor,
          width: _vm.progressWidth + "%",
        },
        on: { transitionend: _vm.onTransitionend },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }