<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2020-09-25 10:59:22.903 -->
<!-- @desc generated by yqg-cli@1.2.9 -->

<template>
    <div class="wrapper">
        <div class="progress">
            <div
                :style="{
                    background: primaryColor,
                    width: `${progressWidth}%`
                }"
                class="inner"
                @transitionend="onTransitionend"
            />
        </div>
    </div>
</template>

<script type="text/babel">
import {mapState} from 'vuex';

export default {
    name: 'Progress',

    props: {
        value: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            default: '#00c989'
        }
    },

    computed: {
        progressWidth() {
            const {value} = this;
            if (value < 0) {
                return 0;
            }

            if (value > 100) {
                return 100;
            }

            return value;
        },

        ...mapState('baseData', [
            'primaryColor'
        ])
    },

    methods: {
        onTransitionend() {
            const {progressWidth} = this;
            if (progressWidth === 100) {
                this.$emit('complete');
            }
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.wrapper {
    padding: 5vw 3.7vw;
    background: #fff;
    border-radius: 8px;

    .progress {
        height: 8px;
        background: #ebebeb;
        border-radius: 8px;
    }

    .inner {
        height: 8px;
        border-radius: 8px;
        position: relative;
        transition: width 3s linear;

        &::after {
            content: "";
            display: block;
            position: absolute;
            right: 2px;
            top: 50%;
            transform: translateY(-2px);
            border-radius: 50%;
            width: 4px;
            height: 4px;
            background: #fff;
        }
    }
}
</style>
